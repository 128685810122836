import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./store";
import { Button, ConfigProvider, Space } from "antd";
import { CountProvider } from "context/CountContext";

var h = window.innerHeight * 0.75;
var w = window.innerWidth * 0.75;
window.resizeTo(w, h);
ReactDOM.createRoot(document.getElementById("root")).render(
  <ConfigProvider
    theme={{
      components: {
        Select: {
          colorBgContainer: "rgb(8, 9, 10)",
          colorBgElevated: "rgb(8, 9, 10)",
          colorText: "rgb(108, 117, 125)",
          colorBorder: "transparent",
          colorPrimary: "rgb(108, 117, 125)",
          colorTextTertiary: "rgb(108, 117, 125)",
          colorTextPlaceholder: "rgb(108, 117, 125)",
          colorIconHover: "rgba(211, 130, 130, 0.88)",
          controlItemBgActive: "rgb(11, 22, 67)",
          colorPrimaryHover: "rgb(8, 9, 10)",
          colorTextQuaternary: "rgb(108, 117, 125)",
          controlOutline: "none",
        },
      },
    }}
  >
    <BrowserRouter>
      <Provider store={store}>
        <CountProvider>
          <App />
        </CountProvider>
      </Provider>
    </BrowserRouter>
  </ConfigProvider>
);
