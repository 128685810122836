import { lazy } from "react";

const pages = [
  {
    path: "/sign-in",
    Component: lazy(() => import("./sign-in/SignIn.page")),
  },
  {
    path: "/sign-up",
    Component: lazy(() => import("./sign-up/SignUp.page")),
  },
];

export const Error404 = lazy(() => import("./error-404/Error404.page"));

export const dashboardPages = [
  {
    path: "/",
    Component: lazy(() => import("./Dashboard/Chatbot/Chatbots.page")),
  },
  {
    path: "/models",
    Component: lazy(() => import("./Dashboard/Models/Model.page")),
  },
  {
    path: "/datasets",
    Component: lazy(() => import("./Dashboard/Datasets/Datasets.page")),
  },

  {
    path: "/datasets/add-dataset",
    Component: lazy(() =>
      import("./Dashboard/Datasets/AddDataset/AddDataset.page.jsx")
    ),
  },

  {
    path: "/Datasets/update-dataset/:name/:id",
    Component: lazy(() =>
      import("./Dashboard/Datasets/UpdateDataset/UpdateDataset.page.jsx")
    ),
  },

  // {
  //   path: "/connected-websites",
  //   Component: lazy(() =>
  //     import("./Dashboard/ConnectedWebsites/ConnectedWebsites.page")
  //   ),
  // },

  {
    path: "/chatbots",
    Component: lazy(() => import("./Dashboard/Chatbot/Chatbots.page")),
  },
  {
    path: "/api-keys",
    Component: lazy(() => import("./Dashboard/APIKeys/APIKeys.page")),
  },
  {
    path: "/pricing",
    Component: lazy(() => import("./Dashboard/ManageSubscription/Pricing.page")),
  },
  {
    path: "/chatbot/:history?/:id",
    Component: lazy(() => import("./Dashboard/Chatbot/Chatbot.page")),
  },
  {
    path: "/manage-subscriptions",
    Component: lazy(() =>
      import("./Dashboard/ManageSubscription/ManageSubscription.page")
    ),
  },
  {
    path: "/settings",
    Component: lazy(() => import("./Dashboard/Settings/Settings.page")),
  },
];
