// src/context/CountContext.js
import { API } from "lib/api";
import React, { createContext, useContext, useEffect, useState } from "react";

const CountContext = createContext();

export const useCountContext = () => useContext(CountContext);

export const CountProvider = ({ children }) => {
  const [dashboardData, setDashboardData] = useState(null);

  const fetchDashboardData = async () => {
    try {
      let res = await API.getDashboardData();
      setDashboardData(res);
    } catch (error) {}
  };
  useEffect(() => {
    fetchDashboardData();
  }, []);

  return (
    <CountContext.Provider value={{ dashboardData, fetchDashboardData }}>
      {children}
    </CountContext.Provider>
  );
};
