// ProtectedRoute.js
import { useDispatch } from "react-redux";
import { Navigate, Outlet, useNavigate } from "react-router-dom";
import { setAuthToken } from "store/Slices/authSlice";

export const ProtectedRoute = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  let t = localStorage.getItem("token");
  t && dispatch(setAuthToken(t));

  const queryParameters = new URLSearchParams(window.location.search);
  const action = queryParameters.get("action");
  const plan = queryParameters.get("plan");
  if (!!action) localStorage.setItem("action", action);
  if (!!plan) localStorage.setItem("plan", plan);

  if (!t) {
    if (!!action) localStorage.setItem("action", action);
    if (!!plan) localStorage.setItem("plan", plan);
    return <Navigate to="/sign-in" />;
  }

  if (
    (!!action || !!plan) &&
    window.location.href.indexOf("manage-subscriptions") < 0
  ) {
    return (
      <Navigate
        to={`/dashboard/manage-subscriptions${window.location.search}`}
      />
    );
  } else return <Outlet />;
};
