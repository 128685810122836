import { configureStore } from '@reduxjs/toolkit';
import authReducer from './Slices/authSlice';
import settingReducer from './Slices/settingsSlice';
import subscriptionReducer from './Slices/subscriptionSlice';
import initialSettingsReducer from './Slices/initialSettingsSlice';
const store = configureStore({
  reducer: {
    auth: authReducer,
    settings: settingReducer,
    subscription: subscriptionReducer,
    initialSettings: initialSettingsReducer,
  },
});
export const messageNotifications = {
  position: 'top-center',
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};
export * from './Actions';
export * from './Slices';

export default store;
