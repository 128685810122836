import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  subscriptionInfo: {},
  isLoading: false,
};

export const subscriptionSlice = createSlice({
  initialState,
  name: "subscription",
  reducers: {
    setSubscriptionLoading: (state) => {
      state.isLoading = true;
    },
    getSubscriptionSuccess(state, { payload }) {
      state.subscriptionInfo = payload;
      state.isLoading = false;
    },
    setSubscriptionFailure: (state, {payload}) => {
      state.subscriptionInfo = {
        active: false,
        free: true,
        tokens: 0,
        freeTokens: payload || 0,
        show_sub_bannner: true
      };
      state.isLoading = false;
    },
  },
});
const { reducer, actions } = subscriptionSlice;

export const {
  setSubscriptionLoading,
  getSubscriptionSuccess,
  setSubscriptionFailure
} = actions;

export default reducer;
