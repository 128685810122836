import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  intialSettings: {},
  isLoading: false,
};

export const initialSettingsSlice = createSlice({
  initialState,
  name: "initialSettings",
  reducers: {
    setInitialSettingsLoading: (state) => {
      state.isLoading = true;
    },
    getInitialSettingsSuccess(state, { payload }) {
      state.initialSettings = payload;
      state.isLoading = false;
    },
    setInitialSettingsFailure: (state, {payload}) => {
      state.isLoading = false;
    },
  },
});
const { reducer, actions } = initialSettingsSlice;

export const {
  setInitialSettingsLoading,
  getInitialSettingsSuccess,
  setInitialSettingsFailure
} = actions;

export default reducer;
